import { useNavigation } from '@react-navigation/native';
import { Button, Icon, Spinner, Text, useTheme } from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { LayoutChangeEvent, Pressable, View } from 'react-native';

import OfflineIcon from '../../../assets/images/warning_icon.svg';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import Assessment from '../../models/Assessment';
import { AppStackNavigation } from '../../navigation';
import { useStore } from '../../stores';
import { getErrors } from '../../utils/errors';
import { getAssessmentIdFormat, useUserId } from '../../utils/helper';
import CustomModal from '../Common/CustomModal';

type Props = {
  assessment: Assessment;
  setRefresh: (status: boolean) => void;
  cameFrom?: string;
};

const AssessmentItem: React.FC<Props> = ({
  assessment,
  cameFrom,
  setRefresh,
}) => {
  const [width, setWidth] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [modalVisibility, setModalVisibility] = useState({
    isErrorVisible: false,
    isDeleteVisible: false,
  });
  const [errorMessage, setErrorMessage] = useState('');

  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const navigation = useNavigation<AppStackNavigation>();

  const store = useStore();
  const {
    assessmentFormStore,
    assessmentStore,
    siteStore,
    userStore,
    setIsAssessmentsChanged,
    setIsStatisticsChanged,
    storageService,
    isInternetReachable,
  } = store;
  const assessmentForm = assessmentFormStore.getAssessmentForm(
    assessment.assessmentForm,
  );

  const site = siteStore.getSite(assessment.site);
  const user = userStore.getUser(assessment.assessor);
  const migratedUser = assessment.migratedAssessor;
  const userId = useUserId();

  const buttonLimit = 68;

  if (!assessmentForm || !site || (!user && !migratedUser)) {
    return <></>;
  }

  const scaler = () => {
    const statusWidth = width * 0.1 - 13;
    const statusFontSize = (statusWidth * 1.2) / 9;
    const buttonWidth = width * 0.05 - 13;
    const buttonFontSize = (buttonWidth * 1.2) / 7;
    return {
      statusFontSize: Math.min(statusFontSize, 14),
      buttonFontSize: Math.min(buttonFontSize, 14),
      buttonWidth: Math.min(buttonWidth, 80),
    };
  };

  const onDelete = async () => {
    if (loading || !assessment.isDraft) {
      return;
    }
    setLoading(true);

    const result = await assessmentStore.deleteAssessment(assessment.id);

    if (result.ok) {
      setIsAssessmentsChanged(true);
      setIsStatisticsChanged(true);
      setModalVisibility({ ...modalVisibility, isDeleteVisible: false });
      setRefresh(true);
    } else {
      setErrorMessage(getErrors(result.errors));
      setModalVisibility({
        ...modalVisibility,
        isDeleteVisible: false,
        isErrorVisible: true,
      });
    }

    setLoading(false);
  };

  const renderDeletePrompt = () => (
    <View style={styles.deleteCard}>
      <OfflineIcon />
      <View style={{ marginTop: 10 }}>
        <Text style={[styles.text, { marginTop: 15 }]}>
          Are you sure you want to delete this item?{'\n\n'}This action cannot
          be undone.
        </Text>
      </View>
      <View style={styles.deleteButtons}>
        <Button
          status="danger"
          size="large"
          appearance="filled"
          style={{ marginRight: 20, width: 200 }}
          disabled={loading}
          accessoryLeft={(props) =>
            loading ? (
              <Spinner status="info" {...props} />
            ) : (
              <Icon
                name="trash-outline"
                width={20}
                height={20}
                fill={theme['text-white']}
              />
            )
          }
          onPress={() => onDelete()}
        >
          Delete
        </Button>
        <Button
          status="primary"
          size="large"
          appearance="outline"
          style={{ width: 200 }}
          disabled={loading}
          accessoryLeft={() => (
            <Icon
              name="close-outline"
              width={24}
              height={24}
              fill={theme['text-dark']}
            />
          )}
          onPress={() =>
            setModalVisibility({ ...modalVisibility, isDeleteVisible: false })
          }
        >
          Cancel
        </Button>
      </View>
    </View>
  );

  const renderErrorMessage = () => (
    <View style={styles.offlineCard}>
      <OfflineIcon />
      <Text style={[styles.text, { marginTop: 10 }]}>An error occurred.</Text>
      <View style={{ marginTop: 15, flex: 1, marginHorizontal: 10 }}>
        <Text style={styles.text}>{errorMessage}</Text>
      </View>
    </View>
  );

  return (
    <>
      <Pressable
        style={styles.assessmentItem}
        onLayout={(e: LayoutChangeEvent) => {
          setWidth(e.nativeEvent.layout.width);
        }}
        onPress={() => {
          if (
            assessment.isDraft &&
            [assessment.assessor, assessment.creator].includes(Number(userId))
          ) {
            navigation.navigate('Edit Assessments', {
              draftAssessmentId: assessment.id,
              cameFrom,
            });
          } else {
            navigation.navigate('View Assessments', {
              assessmentId: assessment.id,
              cameFrom,
            });
          }
        }}
      >
        <Text style={[styles.defaultText, { flex: 0.75 }]}>
          AS-{getAssessmentIdFormat(assessment.id.toString())}
        </Text>
        <Text style={[styles.defaultText, { flex: 3 }]}>
          {assessment.assessmentFormName || assessmentForm.name}
        </Text>
        <Text style={styles.defaultText}>{site.name}</Text>
        <View style={styles.assessorContainer}>
          {user ? (
            <>
              <Text style={styles.defaultText}>
                {`${user.firstName} ${user?.lastName}`}
              </Text>
              <Text style={[styles.defaultText, styles.secondaryText]}>
                {user.email}
              </Text>
            </>
          ) : (
            <Text style={styles.defaultText}>{migratedUser}</Text>
          )}
        </View>
        <Text style={styles.defaultText}>
          {format(parseISO(assessment.dateObserved), 'dd MMM yyyy')}
        </Text>
        <View style={styles.statusContainer}>
          {assessment.isDraft ? (
            <View
              style={[
                styles.statusButton,
                { backgroundColor: theme['green-100'] },
              ]}
            >
              <Text
                style={[
                  styles.statusText,
                  {
                    color: theme['bp-green'],
                    fontSize: scaler().statusFontSize,
                  },
                ]}
              >
                Draft
              </Text>
            </View>
          ) : (
            <View
              style={[
                styles.statusButton,
                { backgroundColor: theme['green-500'] },
              ]}
            >
              <Text
                style={[
                  styles.statusText,
                  {
                    color: theme['text-white'],
                    fontSize: scaler().statusFontSize,
                  },
                ]}
              >
                Submitted
              </Text>
            </View>
          )}
        </View>
        <View style={styles.buttonContainer}>
          {assessment.isDraft &&
          [assessment.assessor, assessment.creator].includes(Number(userId)) ? (
            <>
              <Pressable
                style={[styles.button, { width: scaler().buttonWidth }]}
                onPress={() =>
                  navigation.navigate('Edit Assessments', {
                    draftAssessmentId: assessment.id,
                    cameFrom,
                  })
                }
              >
                <Icon name="edit-outline" width={17} height={17} fill="black" />
                {scaler().buttonWidth > buttonLimit ? (
                  <Text
                    style={[
                      styles.buttonText,
                      {
                        fontSize: scaler().buttonFontSize,
                      },
                    ]}
                  >
                    Edit
                  </Text>
                ) : (
                  <></>
                )}
              </Pressable>
              {isInternetReachable && (
                <Pressable
                  style={[
                    styles.button,
                    { width: scaler().buttonWidth, marginLeft: 5 },
                  ]}
                  onPress={() =>
                    setModalVisibility({
                      ...modalVisibility,
                      isDeleteVisible: true,
                    })
                  }
                >
                  <Icon
                    name="trash-2-outline"
                    width={17}
                    height={17}
                    fill="black"
                  />
                  {scaler().buttonWidth > buttonLimit ? (
                    <Text
                      style={[
                        styles.buttonText,
                        {
                          fontSize: scaler().buttonFontSize,
                        },
                      ]}
                    >
                      Delete
                    </Text>
                  ) : (
                    <></>
                  )}
                </Pressable>
              )}
            </>
          ) : (
            <></>
          )}
        </View>
      </Pressable>
      <CustomModal
        visible={modalVisibility.isDeleteVisible}
        onBackdropPress={() =>
          setModalVisibility({ ...modalVisibility, isDeleteVisible: false })
        }
        children={renderDeletePrompt()}
      />
      <CustomModal
        visible={modalVisibility.isErrorVisible}
        onBackdropPress={() => {
          setModalVisibility({
            ...modalVisibility,
            isErrorVisible: false,
          });
        }}
        children={renderErrorMessage()}
        seconds={3}
      />
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    assessmentItem: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      height: 70,
      borderRadius: 4,
      padding: 16,
      marginBottom: 16,
      backgroundColor: '#FFFFFF',
    },
    defaultText: {
      flex: 1,
      fontSize: 16,
      marginHorizontal: 10,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
    },
    secondaryText: {
      fontSize: 14,
      fontFamily: 'UniversBP_Regular',
      fontWeight: 'normal',
      color: '#666666',
    },
    statusText: {
      fontFamily: 'UniversBP_Regular',
    },
    assessorContainer: {
      flex: 2,
    },
    statusContainer: {
      flex: 1,
      flexDirection: 'row',
    },
    statusButton: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      height: 25,
      maxWidth: 85,
      borderRadius: 12,
      marginHorizontal: 10,
    },
    buttonContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    buttonText: {
      fontWeight: 'bold',
      fontFamily: 'UniversBP_Light',
      marginLeft: 2,
    },
    button: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: 38,
      borderWidth: 1,
      borderRadius: 2,
      borderColor: '#C8C8C8',
    },
    deleteCard: {
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      paddingVertical: 20,
      paddingHorizontal: 47,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 540,
      height: 320,
    },
    deleteButtons: {
      flexDirection: 'row',
      marginTop: 40,
    },
    text: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    offlineCard: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      alignItems: 'center',
      justifyContent: 'center',
      width: 540,
      height: 228,
      borderRadius: 4,
      paddingTop: 42,
      paddingBottom: 62,
    },
  },
});

export default observer(AssessmentItem);
