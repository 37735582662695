import 'react-native-get-random-values';
import { Button, Icon, Spinner, Text, useTheme } from '@ui-kitten/components';
import { format } from 'date-fns';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import { isEqual, isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useFormContext, useWatch } from 'react-hook-form';
import { FlatList, Linking, Pressable, View } from 'react-native';
import Markdown, { MarkdownIt } from 'react-native-markdown-display';
import TurndownService from 'turndown';
import { v4 as uuidv4 } from 'uuid';

import CameraIcon from '../../../assets/images/camera_alt_black_24dp.svg';
import SmallGalleryIcon from '../../../assets/images/gallery_small.svg';
import config from '../../config';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import Question from '../../models/Question';
import { useStore } from '../../stores';
import {
  AnswerForm,
  AnswerFormArray,
  AttachmentForm,
  ImageAttachment,
} from '../../types';
import {
  convertFileToBase64,
  getExtensionFromImageBase64,
  isExtImage,
  isFileImage,
} from '../../utils/helper';
import CustomLinking from '../../utils/linking';
import CommentBox from '../Common/CommentBox';
import CustomModal from '../Common/CustomModal';
import ImageViewer from '../Common/ImageViewer';
// import MarkdownBox from '../Common/MarkdownBox';

type Props = {
  question: Question;
  index: any;
  isDraft?: boolean;
  draftAssessmentId: number;
  setIsChanged: (state: boolean) => void;
  isChanged: boolean;
  submitCounter: number;
  populateLocally: boolean;
  manualAutosave: () => Promise<void>;
  isAutosaving: boolean;
  isDisableAutosave: React.MutableRefObject<boolean>;
};

const EditQuestionFill: React.FC<Props> = ({
  question,
  index,
  isDraft = false,
  draftAssessmentId,
  setIsChanged,
  isChanged,
  submitCounter,
  populateLocally,
  manualAutosave,
  isAutosaving,
  isDisableAutosave,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const [isVisible, setIsVisible] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [imageAttachment, setImageAttachment] = useState<ImageAttachment>();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const fileIndex = useRef(0);
  const fileCount = useRef(0);
  const isFirstRender = useRef(true);
  const initialSetValueRef = useRef(true);
  const theme = useTheme();
  const store = useStore();
  const { assessmentStore, storageService, isInternetReachable } = store;
  const tdservice = new TurndownService();
  tdservice.escape = (string) => string;

  const markdownItInstance = MarkdownIt({ typographer: true, linkify: true });

  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<AnswerFormArray>();

  const localAnswerData = useWatch({ name: `answers.${index}` }) as AnswerForm;

  const answerModel = useMemo(
    () =>
      assessmentStore
        .getAnswers()
        .find(
          (answer) =>
            answer.assessment === draftAssessmentId &&
            answer.question === question.id,
        ),
    [draftAssessmentId, submitCounter, assessmentStore.answers.values()],
  );
  const answer = answerModel?.answer || '';
  const description = answerModel?.description || '';

  const existingAttachments = useMemo(() => {
    const attachments = assessmentStore
      .getAttachments()
      .filter((attachment) => attachment.answer === answerModel?.id);
    const attachmentList: AttachmentForm[] = [];
    attachments.forEach((attachment) => {
      const apiUrl = config.urls.api.replace('api/', '');
      const attachmentUrl =
        config.env === 'local'
          ? `${apiUrl}${attachment.attachment.substring(1)}`
          : attachment.attachment;
      const ext = attachment.attachmentName.split('.').pop();
      attachmentList.push({
        id: attachment.id,
        file: attachmentUrl,
        count: fileCount.current,
        name: attachment.attachmentName,
        isSaved: true,
        toDelete: false,
        isImage: isExtImage(ext),
      });
      fileCount.current += 1;
    });
    const listLength = attachmentList.length;
    fileIndex.current = listLength;
    return attachmentList;
  }, [answerModel, submitCounter]);

  const watchAnswer = useWatch({ name: `answers.${index}.answer` });
  const watchAttachments = useWatch({ name: `answers.${index}.attachments` });
  const watchDescription = useWatch({ name: `answers.${index}.description` });

  useEffect(() => {
    register(`answers.${index}.answer`, {
      required: { value: !isDraft, message: 'Answer is required' },
      value: answer,
    });
    register(`answers.${index}.description`, {
      required: {
        value: !isDraft
          ? (getValues().answers &&
              getValues().answers[index].answer &&
              getValues().answers[index].answer !== 'Yes') ||
            question.isDescriptionRequired
          : false,
        message: 'Comment is required',
      },
      value: description,
    });
  }, [register, initialLoading, isDraft, watchAnswer]);

  useEffect(() => {
    if (answerModel) {
      register(`answers.${index}.id`, {
        required: true,
        value: answerModel.id,
      });
    }
    register(`answers.${index}.question`, {
      required: true,
      value: `${question.id}`,
    });
    register(`answers.${index}.questionVersionId`, {
      required: true,
      value: `${question.versionId}`,
    });
    register(`answers.${index}.attachments`, {
      required: false,
      value: existingAttachments,
    });
  }, [register, initialLoading]);

  useEffect(() => {
    if (populateLocally) {
      if (localAnswerData.id) {
        setValue(`answers.${index}.id`, localAnswerData.id);
      }
      setValue(`answers.${index}.answer`, localAnswerData.answer);
      setValue(`answers.${index}.description`, localAnswerData.description);
      setValue(`answers.${index}.question`, `${localAnswerData.question}`);
      setValue(
        `answers.${index}.questionVersionId`,
        `${localAnswerData.questionVersionId}`,
      );
      setValue(`answers.${index}.attachments`, localAnswerData.attachments);
    } else {
      if (answerModel) {
        setValue(`answers.${index}.id`, answerModel.id);
      }
      setValue(`answers.${index}.answer`, answer);
      setValue(`answers.${index}.description`, description);
      setValue(`answers.${index}.question`, `${question.id}`);
      setValue(`answers.${index}.questionVersionId`, `${question.versionId}`);
      setValue(`answers.${index}.attachments`, existingAttachments);
    }
  }, []);

  useEffect(() => {
    if (initialSetValueRef.current) {
      initialSetValueRef.current = false;
      return;
    }
    isDisableAutosave.current = true;
    if (answerModel) {
      setValue(`answers.${index}.id`, answerModel.id);
    }
    setValue(`answers.${index}.attachments`, existingAttachments);
  }, [submitCounter]);

  useEffect(() => {
    if (initialLoading) {
      if (populateLocally) {
        if (
          watchAnswer === localAnswerData.answer &&
          watchDescription === localAnswerData.description &&
          isEqual(watchAttachments, localAnswerData.attachments)
        ) {
          setInitialLoading(false);
          fileIndex.current = localAnswerData.attachments.length;
          fileCount.current = localAnswerData.attachments.length;
        }
      } else {
        if (
          watchAnswer === answer &&
          watchDescription === description &&
          isEqual(watchAttachments, existingAttachments)
        ) {
          setInitialLoading(false);
        }
      }
    }
  }, [watchAnswer, watchDescription, watchAttachments]);

  const changed =
    answer !== getValues(`answers.${index}.answer`) ||
    description !== getValues(`answers.${index}.description`) ||
    existingAttachments.length !== fileIndex.current;

  const attachmentList = useMemo(() => {
    return watchAttachments
      ? watchAttachments.filter(
          (attachment: AttachmentForm) => !attachment.toDelete,
        )
      : existingAttachments;
  }, [watchAttachments, submitCounter]);

  const flagChanges = () => {
    if (!isChanged) {
      setIsChanged(true);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (changed) {
      flagChanges();
    }
  }, [changed]);

  const onUpload = async () => {
    if (isAutosaving) {
      return;
    }

    const result = await DocumentPicker.getDocumentAsync({
      type: '*/*',
      multiple: true,
    });
    if (result && result.output && !result.canceled) {
      for (const file of Array.from(result.output)) {
        const base64 = await convertFileToBase64(file);
        const uuidKey: string = uuidv4();
        await storageService.addToSet(
          `${config.filesKeyPrefix}${draftAssessmentId}`,
          uuidKey,
          base64,
        );
        const attachment = {
          key: uuidKey,
          name: file.name,
          count: fileCount.current,
          isSaved: false,
          isImage: isFileImage(file),
          toDelete: false,
        };
        setValue(
          `answers.${index}.attachments.${fileIndex.current}`,
          attachment,
        );
        fileIndex.current += 1;
        fileCount.current += 1;
      }

      if (isInternetReachable) {
        await manualAutosave();
      }

      setIsVisible(false);
    }
  };

  const onTakePhoto = async () => {
    if (isAutosaving) {
      return;
    }

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 0,
    });
    if (result && result.assets && result.assets[0].uri && !result.canceled) {
      const image = result.assets[0].uri;
      const ext = getExtensionFromImageBase64(image);
      const name = `${fileCount.current}-photo-${format(
        new Date(),
        'dd/mm/yy hh:mm:ss',
      )}.${ext}`;
      const uuidKey: string = uuidv4();
      await storageService.addToSet(
        `${config.filesKeyPrefix}${draftAssessmentId}`,
        uuidKey,
        image,
      );
      const photo = {
        key: uuidKey,
        name,
        count: fileCount.current,
        isSaved: false,
        isImage: true,
        toDelete: false,
      };
      setValue(`answers.${index}.attachments.${fileIndex.current}`, photo);
      fileIndex.current += 1;
      fileCount.current += 1;

      if (isInternetReachable) {
        await manualAutosave();
      }

      setIsVisible(false);
    }
  };

  const onFileDelete = (fileCount: number) => {
    if (isAutosaving) {
      return;
    }

    const deleteIndex: number = getValues(
      `answers.${index}.attachments`,
    ).findIndex((item: AttachmentForm) => item.count === fileCount);
    if (getValues(`answers.${index}.attachments.${deleteIndex}.isSaved`)) {
      setValue(`answers.${index}.attachments.${deleteIndex}.toDelete`, true);
      flagChanges();
    } else {
      const attachments = getValues(`answers.${index}.attachments`).filter(
        (attachment: AttachmentForm) => attachment.count !== fileCount,
      );
      setValue(`answers.${index}.attachments`, attachments);
      if (fileIndex.current > 0) {
        fileIndex.current -= 1;
      }
    }
  };

  const onFilePress = async (item: AttachmentForm) => {
    const openImageViewer = async (file: string) => {
      setImageAttachment({ ...item, file });
      setIsImageVisible(true);
    };

    const openExternalLink = async (file: string) => {
      const canOpen = await Linking.canOpenURL(file);
      if (canOpen) {
        CustomLinking.openURL(file, '_blank');
      }
    };

    if (item.isImage) {
      if (item.file && item.isSaved) {
        await openImageViewer(item.file);
      } else if (item.key) {
        const savedFile = await storageService.getItemAsync(item.key);
        if (savedFile) {
          await openImageViewer(savedFile);
        }
      }
    } else if (item.file && item.isSaved) {
      await openExternalLink(item.file);
    }
  };

  const renderFileItem = useCallback(
    ({ item }: { item: AttachmentForm }) => {
      return (
        item && (
          <View style={styles.fileView}>
            {isInternetReachable ? (
              <Pressable
                onPress={() => onFilePress(item)}
                style={{ flexDirection: 'row' }}
              >
                <View style={styles.icon}>
                  {isAutosaving && !item.isSaved ? (
                    <Spinner status="basic" size="tiny" />
                  ) : !item.isImage ? (
                    <Icon
                      name="arrow-downward-outline"
                      width={22}
                      height={22}
                      fill={theme['text-dark']}
                    />
                  ) : (
                    <SmallGalleryIcon />
                  )}
                </View>
                <View style={[styles.fileName, { width: 100 }]}>
                  <Text numberOfLines={1} style={styles.fileText}>
                    {isAutosaving && !item.isSaved ? 'Uploading...' : item.name}
                  </Text>
                </View>
              </Pressable>
            ) : item.isImage ? (
              <Pressable
                onPress={() => onFilePress(item)}
                style={{ flexDirection: 'row' }}
              >
                <View style={styles.icon}>
                  <SmallGalleryIcon />
                </View>
                <View style={[styles.fileName, { width: 100 }]}>
                  <Text numberOfLines={1} style={styles.fileText}>
                    {item.name}
                  </Text>
                </View>
              </Pressable>
            ) : (
              <View style={[styles.fileName, { width: 124 }]}>
                <Text numberOfLines={1} style={styles.fileText}>
                  {item.name}
                </Text>
              </View>
            )}
            <View style={styles.icon}>
              <Pressable
                disabled={isAutosaving}
                onPress={() => onFileDelete(item.count!)}
              >
                <Icon
                  name="close-outline"
                  width={22}
                  height={22}
                  fill={isAutosaving ? theme['text-grey'] : theme['text-dark']}
                />
              </Pressable>
            </View>
          </View>
        )
      );
    },
    [isAutosaving, isInternetReachable],
  );

  const renderAddFiles = () => (
    <View style={styles.card}>
      <Text style={styles.title}>Add attachments from</Text>
      <View style={styles.row}>
        <View style={styles.iconCard}>
          <Pressable style={styles.iconButton} onPress={onUpload}>
            <Icon
              name="folder-outline"
              width={76}
              height={76}
              fill={theme['text-dark']}
            />
          </Pressable>
          <Text style={styles.subtitle}>Files</Text>
        </View>
        <View style={styles.iconCard}>
          <Pressable style={styles.iconButton} onPress={onTakePhoto}>
            <CameraIcon />
          </Pressable>
          <Text style={styles.subtitle}>Camera</Text>
        </View>
      </View>
    </View>
  );

  const renderImageViewer = () => (
    <ImageViewer
      attachment={imageAttachment!}
      setIsImageVisible={setIsImageVisible}
    />
  );

  const onUploadBackdropPress = useCallback(() => {
    if (isAutosaving) {
      return;
    }
    setIsVisible(false);
  }, [isAutosaving]);

  return (
    <>
      <View style={styles.container}>
        {!initialLoading && (
          <>
            <View style={styles.left}>
              <View style={styles.questionContainer}>
                <Text style={styles.questionNumber}>
                  Question {index + 1}{' '}
                  {question.isDescriptionRequired ? '(comment required)' : ''}
                </Text>
                <Markdown
                  style={{
                    body: styles.question,
                  }}
                  markdownit={markdownItInstance}
                >
                  {tdservice.turndown(
                    question.question.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                  )}
                </Markdown>
                <Markdown
                  style={{
                    body: styles.description,
                  }}
                  markdownit={markdownItInstance}
                >
                  {tdservice.turndown(
                    question.description.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                  )}
                </Markdown>
              </View>
              <View style={styles.buttonContainer}>
                <Button
                  status="success"
                  size="medium"
                  appearance="outline"
                  style={[
                    { marginBottom: 8 },
                    getValues(`answers.${index}.answer`) === 'Yes' &&
                      styles.yesButton,
                  ]}
                  onPress={() => setValue(`answers.${index}.answer`, 'Yes')}
                >
                  Yes
                </Button>
                <Button
                  status="danger"
                  size="medium"
                  appearance="outline"
                  style={[
                    { marginBottom: 8 },
                    getValues(`answers.${index}.answer`) === 'No' &&
                      styles.noButton,
                  ]}
                  onPress={() => setValue(`answers.${index}.answer`, 'No')}
                >
                  No
                </Button>
                <Button
                  status="info"
                  size="medium"
                  appearance="outline"
                  style={[
                    getValues(`answers.${index}.answer`) === 'N/A' &&
                      styles.naButton,
                  ]}
                  onPress={() => setValue(`answers.${index}.answer`, 'N/A')}
                >
                  N/A
                </Button>
              </View>
            </View>
            <View style={styles.right}>
              <View style={styles.commentContainer}>
                <CommentBox
                  error={
                    errors && errors.answers
                      ? !isUndefined(errors.answers[index]?.description)
                        ? errors.answers[index]!.description
                        : !isUndefined(errors.answers[index]?.answer)
                        ? errors.answers[index]!.answer
                        : undefined
                      : undefined
                  }
                  setValue={(text) =>
                    setValue(`answers.${index}.description`, text)
                  }
                  draftComment={getValues(`answers.${index}.description`)}
                />
              </View>
              <View style={styles.photosContainer}>
                <Button
                  status="primary"
                  size="small"
                  appearance="outline"
                  style={{ width: '100%' }}
                  disabled={isAutosaving}
                  accessoryLeft={() => (
                    <Icon
                      name="plus-outline"
                      width={24}
                      height={24}
                      fill={
                        isAutosaving ? theme['text-grey'] : theme['text-dark']
                      }
                    />
                  )}
                  onPress={!isMobile ? onUpload : () => setIsVisible(true)}
                >
                  Add files
                </Button>
                <FlatList
                  style={styles.fileList}
                  data={initialLoading ? [] : attachmentList}
                  keyExtractor={(item: AttachmentForm) => `${item.count}`}
                  renderItem={renderFileItem}
                  showsHorizontalScrollIndicator={false}
                />
              </View>
            </View>
          </>
        )}
      </View>
      <CustomModal
        visible={isVisible}
        onBackdropPress={onUploadBackdropPress}
        children={renderAddFiles()}
      />
      <CustomModal
        visible={isImageVisible}
        onBackdropPress={() => setIsImageVisible(false)}
        children={renderImageViewer()}
      />
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      paddingLeft: 16,
      paddingRight: 19,
      paddingBottom: 32,
      paddingTop: 6,
      backgroundColor: 'input-field-background',
      alignContent: 'stretch',
      minHeight: 168,
    },
    card: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      paddingVertical: 40,
      alignItems: 'center',
      justifyContent: 'center',
      width: 540,
      height: 281,
      borderRadius: 4,
      zIndex: 100,
    },
    title: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      alignSelf: 'center',
      textAlign: 'center',
    },
    row: {
      flexDirection: 'row',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 317,
      height: 148,
      marginTop: 37,
    },
    icon: {
      width: 20,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 5,
    },
    iconCard: {
      flexDirection: 'column',
      alignItems: 'center',
      width: 120,
    },
    iconButton: {
      width: 120,
      height: 120,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
      borderColor: 'gray-01',
      borderWidth: 1,
      borderRadius: 2,
    },
    subtitle: {
      fontFamily: 'UniversBP_Regular',
      fontSize: 14,
      textAlign: 'center',
    },
    backdrop: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 0,
    },
    fileView: {
      flexDirection: 'row',
      width: 150,
      height: 22,
      marginBottom: 9,
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    fileText: {
      fontFamily: 'UniversBP_Regular',
      fontWeight: 'normal',
      fontSize: 14,
      textAlign: 'left',
    },
    fileList: {
      marginTop: 17,
      flex: 1,
    },
    fileName: {
      overflow: 'hidden',
      height: 22,
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    left: {
      flex: 0.46,
      flexDirection: 'row',
      paddingRight: 32,
    },
    right: {
      flex: 0.54,
      flexDirection: 'row',
    },
    questionContainer: {
      paddingRight: 40,
      paddingTop: 18,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    questionNumber: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 14,
      color: 'text-dark',
    },
    question: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    description: {
      paddingTop: 8,
      fontFamily: 'UniversLTPro_Regular',
      fontSize: 14,
      color: 'blue-01',
    },
    buttonContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingTop: 28,
      flexBasis: 144,
    },
    yesButton: {
      borderColor: 'button-success-outline-active',
      backgroundColor: 'button-success-background-active',
    },
    noButton: {
      borderColor: 'button-danger-outline-active',
      backgroundColor: 'button-danger-background-active',
    },
    naButton: {
      borderColor: 'button-info-outline-active',
      backgroundColor: 'button-info-background-active',
    },
    commentContainer: {
      flex: 1,
      flexDirection: 'column',
      paddingRight: 32,
    },
    photosContainer: {
      width: 150,
      flexDirection: 'column',
      paddingTop: 28,
    },
  },
});

export default observer(EditQuestionFill);
